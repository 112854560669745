import { MonitorDot, PictureInPicture2 } from "lucide-react";

const menuData: any = [
    {
        label: 'menu',
        isTitle: true,
    },
    {
        id: "dashboard",
        label: 'Dashboards',
        link: "/dashboard",
        icon: <MonitorDot />,
    },
    {
        id: "landing",
        label: 'Churches',
        link: "/#",
        icon: <PictureInPicture2 />,
        subItems: [
            {
                id: 'onepage',
                label: 'View',
                link: '/view-churches',
                parentId: "landing"
            },
        ]
    },
    {
        id: "users",
        label: 'Users',
        link: "/#",
        icon: <PictureInPicture2 />,
        subItems: [
            {
                id: 'onepage',
                label: 'View',
                link: '/view-users',
                parentId: "landing"
            },
        ]
    },
    

];

export { menuData };